<template>
  <div> 
    <div class="home">
        <b-navbar toggleable="lg" type="dark" variant="dark">
          <b-navbar-brand href="#">qAssess Online</b-navbar-brand>
          <b-navbar-nav class="ml-auto">         
              <b-button variant="info" @click="$router.push({ name: 'Logout'})"><b-icon-box-arrow-right></b-icon-box-arrow-right> &nbsp; Logout</b-button>
          </b-navbar-nav>
        </b-navbar>

        <div class="container mt-3">
          <div class="row justify-content-center">
            <div class="col-xs-12 col-lg-8">
              <b-alert variant="danger" class="d-block d-sm-none" show><strong>Warning:</strong> Screen/browser window is too narrow to properly use qAssess.  Widen the window or use a larger device.</b-alert>

              <StudentInfo />
              <br>
              <TaskList />
              
              <br><br>
              <hr>
              <b-card header="Preview Note"
                      header-text-variant="white"
                      header-tag="header"
                      header-bg-variant="secondary">
                  <b-card-text>
                      <b-container>
                          <p class="text-left">In this <em>Preview edition</em>, you see four sample tasks from various grade levels.
                          This will allow you to test your browser for compatiblity, and understand the task interface.  
                          In this preview, you may view each task as many times as you wish, they will not be disabled.</p>
                          <p class="text-left">In the <em>Live Edition</em>, you will see the five tasks assigned to your exam.  Once you complete a live task, 
                          the button will be disabled and marked as complete. </p>
                      </b-container>
                  </b-card-text>
              </b-card> 
                             
            </div>
          </div>
        </div>   
    </div>
  </div>
</template>

<script>
import TaskList from '@/components/TaskList.vue'
import StudentInfo from '@/components/StudentInfo.vue'

export default {
  name: 'Home',
  components: {
    TaskList,
    StudentInfo
  } 
}
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>