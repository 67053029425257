<template>
    <div>
        <b-card header-tag="header">
            <template #header>
                Student: &nbsp; <strong>Taylor B Smith</strong>
            </template>
            <b-card-text>
                <b-container>
                    <b-row>
                        <b-col sm="12" md="6" class="text-left">                                
                            District: <strong>Example District</strong><br>
                            School: <strong>Example School</strong><br>
                            Teacher: <strong>Ms. Jones</strong><br>
                        </b-col>
                        <b-col sm="12" md="6" class="text-left">
                            Class: <strong>Example Math Class</strong><br>
                            Grade: <strong>6</strong>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card-text>
            <div class="wronginfo mt-2">If this is not you or you're assigned to the wrong exam, STOP and contact your teacher immediately.</div>
        </b-card>        
    </div>
</template>

<script>
export default {
    name: 'StudentInfo'
}
</script>

<style scoped>
    .card-header {
        font-size: 120%;
        background-color: #D7D7D7;
    }
    .wronginfo {
        font-size: 0.75rem;
        font-weight: bold;
        color: red;
    }
</style>