<template>
    <div>
        <h2>Exam: Sample Tasks</h2>

        <p class="intro-text">
            Each task is completed independently; but once you start a specific task, 
            you must complete it all at one time.  You may not save a partial task and return to it.
            Completed tasks will be greyed out.
        </p>
        <p class="intro-text">
            Follow your instructor's direction with regard to when to complete each task.
        </p>        

        <div class="m-4">
            <b-button block variant="info" @click="$router.push({ name: 'ExamTask', params: { examStudentTaskId: 2 }})">
              Grade 2: <strong>Favorite Shapes</strong>
            </b-button>
        </div>

        <div class="m-4">
            <b-button block variant="info" @click="$router.push({ name: 'ExamTask', params: { examStudentTaskId: 4 }})">
              Grade 4: <strong>David’s Robot</strong>
            </b-button>
        </div>

        <div class="m-4">
            <b-button block variant="info" @click="$router.push({ name: 'ExamTask', params: { examStudentTaskId: 6 }})">
              Grade 6: <strong>Awards Dinner</strong>
            </b-button>
        </div>

        <div class="m-4">
            <b-button block variant="info" @click="$router.push({ name: 'ExamTask', params: { examStudentTaskId: 9 }})">
              Algebra I: <strong>Arguing Algebra</strong>
            </b-button>
        </div>        
    </div>
</template>

<script>

export default {
    name: 'TaskList'
}
</script>

<style scoped>
    button {
        min-width: 200px;
    }
    .intro-text {
        font-size: 80%;
    }
</style>